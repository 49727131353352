<template>
    <div class="mb-3 card">
        <div>
            <v-container fluid>

                    <p>{{$t('settings.lang_changePasswordHeader')}} </p>
                    <p>{{$t('generic.lang_gui_logged_as')}}: {{ user.name }} {{ user.last_name }} </p>
                <v-form v-model="isFormValid">
                <v-layout>

                    <v-flex xs12 md6 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :append-icon="pwshow1 ? 'lnr-eye' : 'lnr-eye'"
                                      :rules="[rules.required, rules.min]"
                                      :type="pwshow1 ? 'text' : 'password'"
                                      v-model="password"
                                      :label="$t('generic.lang_neues_passwort')"
                                      :hint="$t('generic.lang_atLeast') + ' 8 ' + $t('generic.lang_characters')"
                                      value=""
                                      class="input-group--focused"
                                      @click:append="pwshow1 = !pwshow1"
                        ></v-text-field>
                        <password v-model="password" :strength-meter-only="true"/>
                    </v-flex>

                    <v-flex xs12 md6 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :append-icon="pwshow2 ? 'lnr-eye' : 'lnr-eye'"
                                      :rules="[rules.required, rules.passwordMatch]"
                                      :type="pwshow2 ? 'text' : 'password'"
                                      :label="$t('generic.lang_neues_passwort_rep')"
                                      v-model="passwordRepeat"
                                      value=""
                                      @click:append="pwshow2 = !pwshow2"
                        ></v-text-field>
                    </v-flex>


                </v-layout>


                <v-layout class="text-right">
                    <v-flex>
                        <v-btn color="success" :disabled="!isFormValid" @click="updatePassword">{{ $t('generic.lang_edit') }}</v-btn>
                    </v-flex>
                </v-layout>
                </v-form>
            </v-container>
        </div>
        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>



<script>
    import {ENDPOINTS} from '../../config'
    import {Events} from "../../plugins/events";
    import mixin from "../../mixins/KeyboardMixIns";
    import {mapState} from "vuex";
    import Password from 'vue-password-strength-meter'
    import sha512 from "js-sha512";
    export default {
        components: {
            Password
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                pwshow1: false,
                pwshow2: false,
                rules: {
                    required: value => !!value || this.$t("generic.lang_requiredField"),
                    min: v => v.length >= 8 || this.$t('generic.lang_min') + ' 8 ' + this.$t('generic.lang_characters'),
                    passwordMatch: () => (this.password === this.passwordRepeat) || (this.$t("generic.lang_passwordYouEnteredDontMatch")),
                },
                isFormValid: false,
                password: "",
                passwordRepeat: "",
                name: "",
                last_name: "",
                // ----------------------------------

            }
        },
        computed: {
            ...mapState([
                'user',
            ])
        },
        methods: {

            updatePassword: function () {
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.PASSWORD.UPDATE, {
                    changePW: sha512(this.password),
                    changePWRepeat: sha512(this.passwordRepeat),

                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });

                        this.password = "";
                        this.passwordRepeat = "";
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            }
        },

    }
</script>